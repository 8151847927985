@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.eot");
  src: url("Gilroy-Bold.eot") format("embedded-opentype"),
    url("Gilroy-Bold.woff2") format("woff2"),
    url("Gilroy-Bold.woff") format("woff"),
    url("Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.eot");
  src: url("Gilroy-Regular.eot") format("embedded-opentype"),
    url("Gilroy-Regular.woff2") format("woff2"),
    url("Gilroy-Regular.woff") format("woff"),
    url("Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBold.eot");
  src: url("Gilroy-SemiBold.eot") format("embedded-opentype"),
    url("Gilroy-SemiBold.woff2") format("woff2"),
    url("Gilroy-SemiBold.woff") format("woff"),
    url("Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
/* inter font */
/* inter */

@font-face {
  font-family: "Inter";
  src: url("Inter-Regular.eot");
  src: url("Inter-Regular.eot") format("embedded-opentype"),
    url("Inter-Regular.woff2") format("woff2"),
    url("Inter-Regular.woff") format("woff"),
    url("Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("Inter-Bold.eot");
  src: url("Inter-Bold.eot") format("embedded-opentype"),
    url("Inter-Bold.woff2") format("woff2"),
    url("Inter-Bold.woff") format("woff"),
    url("Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
