/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
// inmport fonts
@import "../src/assets/fonts/stylesheet.css";
// importing variables
@import "/src/scss/abstracts/variables";
html {
  scroll-behavior: smooth;
}
body {
  font-family: $main-font;
}
.arab {
  text-align: right !important;
}

.active {
  color: #00beff !important;
  border-bottom: 3px solid #00beff;
}
.pt-70 {
  padding-top: 70px !important;
}
